import React, {useState} from 'react';
import FluentyLogo from "../assets/FLUENTY.svg";
import {HiMenuAlt3} from "react-icons/hi";

// const mainRouter = createBrowserRouter(
//     createRoutesFromElements(
//         <Route path="/" element={<Nav/>}>
//             <Route path="/" element={<Home title={"Home Page"}/>}/>
//             <Route path="/about" element={<About title={"About Us Page"}/>}/>
//             <Route path="/services" element={<Services title={"Services Page"}/>}/>
//             <Route path="/showcase" element={<Showcase title={"Showcase Page"}/>}/>
//             <Route path="/contact" element={<Contact title={"Contact Us Page"}/>}/>
//         </Route>
//     )
// );

function Nav() {

    const [nav, setNav] = useState(false);

    const handleNav = () => {
        setNav(!nav);
    }

    return (

        <div className='absolute w-full flex justify-between p-4 items-center'>
            <img className="h-16 z-20 cursor-pointer" src={FluentyLogo} alt="Logo"/>
            <HiMenuAlt3 onClick={handleNav} size={35} className='z-20 text-white cursor-pointer'/>

            <div
                className={
                nav ? "ease-out duration-500 fixed text-gray-300 left-0 top-0 w-full h-screen bg-black/90 px-4 py-7 flex-col z-10"
                    : 'ease-in duration-500 absolute top-0 h-screen left-[-100%] z-10'
            }>
                <ul className='flex flex-col fixed w-full h-full items-center justify-center'>
                    <li className="font-bold text-3xl p-8">Home</li>
                    <li className="font-bold text-3xl p-8">About</li>
                    <li className="font-bold text-3xl p-8">Services</li>
                    <li className="font-bold text-3xl p-8">Showcase</li>
                    <li className="font-bold text-3xl p-8">Contact</li>
                </ul>
            </div>
        </div>
    );
}

export default Nav;