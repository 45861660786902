import React from 'react';
import HeroImage from '../assets/FLUENTY_FRONT-PAGE_NEW-2.jpg';

function Hero() {
    return (
        <div className='w-full h-screen'>

            <img className='top-0 left-0 w-full h-screen object-cover' src={HeroImage} alt="Fluenty Logo"/>

            <div className='bg-black/30 absolute top-0 left-0 w-full h-screen'/>

            <div className='absolute top-0 w-full h-full flex flex-col justify-center text-white p-4'>

                <div className='
                md:left-[10%]
                md:right-[10%]
                md:top-[30%]
                md:bottom-[10%]
                xl:left-[10%]
                xl:right-[10%]
                xl:top-[30%]
                left-[10%] right-[10%] top-[30%] bottom-[10%]
                max-w-[1100px]
                xl:p-0
                xl:m-0
                m-auto
                absolute
                p-4
                '>
                    <h1 className="
                    font-bold
                    uppercase
                    xl:text-6xl
                    md:text-5xl
                    text-4xl
                    drop-shadow-2xl"
                    >
                        Being a Tech Savvy Company Doesn't Mean You Have To Learn To Write Code
                    </h1>
                    <h3 className='
                    max-w-[600px]
                    xl:text-4xl
                    md:text-3xl
                    text-2xl
                    drop-shadow-2xl
                    py-2
                    '>
                        - this is our strength and we are here to support you every step of the way.
                    </h3>
                    <button>Get Started</button>
                </div>
            </div>
        </div>
    );
}

export default Hero;