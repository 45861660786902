import React from 'react';
import Nav from "./components/Nav";
import Hero from "./components/Hero";

function App() {
    return (
        <>
            <Nav/>
            <Hero/>
        </>
    );
};


export default App;
